import { WebBackendProvider } from './WebBackendProvider'
import auth0Configuration from './auth0.json'
import styles from './globals.css?url'
import type { AppState } from '@auth0/auth0-react'
import { Auth0Provider, withAuthenticationRequired } from '@auth0/auth0-react'
import { IntercomProvider, LayoutWithoutAuth } from '@components'
import '@mantine/charts/styles.css'
import { MantineProvider, ColorSchemeScript } from '@mantine/core'
import '@mantine/core/styles.css'
import '@mantine/dates/styles.css'
import '@mantine/dropzone/styles.css'
import { ModalsProvider } from '@mantine/modals'
import { Notifications } from '@mantine/notifications'
import '@mantine/notifications/styles.css'
import type { LinksFunction } from '@remix-run/node'
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useNavigate,
  useRouteError,
  isRouteErrorResponse,
} from '@remix-run/react'
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { MINUTE, HOUR, Logger } from '@terros/common'
import { theme } from '@theme'
import 'mantine-datatable/styles.css'
import { NuqsAdapter } from 'nuqs/adapters/remix'
import { useState } from 'react'

export const links: LinksFunction = () => [
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
  { rel: 'preconnect', href: 'https://fonts.gstatic.com', crossOrigin: 'anonymous' },
  { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css2?family=Sora&display=swap' },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200',
  },
  { rel: 'stylesheet', href: styles },
]

const logger = new Logger('RootLayout')

const LayoutWithAuth = withAuthenticationRequired(LayoutWithoutAuth)

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang='en'>
      <head>
        <meta charSet='utf-8' />
        <meta content='minimum-scale=1, initial-scale=1, width=device-width, user-scalable=no' name='viewport' />
        <Meta />
        <Links />
        <ColorSchemeScript defaultColorScheme='auto' />
      </head>
      <body>
        <MantineProvider defaultColorScheme='light' theme={theme}>
          <Notifications pr={70} />
          {children}
        </MantineProvider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}

function App() {
  const navigate = useNavigate()

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: { staleTime: 20 * MINUTE, gcTime: HOUR },
        },
      })
  )

  const onRedirectCallback = (appState?: AppState): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- needed for auth0
    // @ts-ignore
    navigate(appState?.returnTo || '/')
  }
  return (
    <NuqsAdapter>
      <QueryClientProvider client={queryClient}>
        <Auth0Provider
          authorizationParams={{
            redirect_uri: typeof window !== 'undefined' ? window.location.origin : undefined,
          }}
          clientId={auth0Configuration.clientId}
          domain={auth0Configuration.domain}
          onRedirectCallback={onRedirectCallback}
        >
          <WebBackendProvider>
            <LayoutWithAuth>
              <ModalsProvider>
                <IntercomProvider>
                  <Outlet />
                </IntercomProvider>
              </ModalsProvider>
            </LayoutWithAuth>
          </WebBackendProvider>
        </Auth0Provider>
      </QueryClientProvider>
    </NuqsAdapter>
  )
}

export default withSentry(App)

export function ErrorBoundary() {
  const error = useRouteError()
  captureRemixErrorBoundaryError(error)
  return (
    <html lang='en'>
      <head>
        <title>Oops!</title>
        <Meta />
        <Links />
      </head>
      <body>
        <h1 className='text-center'>
          {isRouteErrorResponse(error)
            ? `${error.status} ${error.statusText}`
            : error instanceof Error
              ? error.message
              : 'Unknown Error'}
        </h1>
        <Scripts />
      </body>
    </html>
  )
}
