import { boot, load, update } from './intercom'
import { useIntercomHash } from '@front/model'
import { useLocation } from '@remix-run/react'
import { getName } from '@terros/common'
import { posthog } from 'posthog-js'
import { type ReactNode, useEffect, useRef } from 'react'

export const IntercomProvider = ({ children }: { children: ReactNode }): ReactNode => {
  const { pathname, search: searchParams } = useLocation()
  const { data } = useIntercomHash('web')
  const booted = useRef(false)

  useEffect(() => {
    if (data) {
      posthog.identify(data.user.userId, {
        email: data.user.email,
        name: getName(data.user),
        companyId: data.company.companyId,
      })
      posthog.group('company', data.company.companyId, {
        name: data.company.name,
      })
    }
  }, [data])

  useEffect(() => {
    if (typeof window !== 'undefined' && data && !booted.current) {
      const { hash, user, company } = data
      load()
      boot({
        user_id: user.userId,
        created_at: user.firstAccess ? user.firstAccess / 1000 : undefined,
        user_hash: hash,
        email: user.email,
        name: getName(user),
        phone: user.phone,
        avatar: {
          type: 'avatar',
          image_url: user.avatarUrl,
        },
        company: {
          id: company.companyId,
          name: company.name,
          created_at: company.createdAt ? company.createdAt / 1000 : undefined,
        },
      })
      booted.current = true
    }
  }, [data])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      update()
    }
  }, [pathname, searchParams])

  return children
}
