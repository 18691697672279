// @ts-nocheck
// Intercom App ID
export const APP_ID = 'zebvgyjo'

// Load must be run first, then boot

/**
 * @see https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app
 */
export const load = (): void => {
  const w = window
  const ic = w.Intercom
  if (typeof ic === 'function') {
    ic('reattach_activator')
    ic('update', w.intercomSettings)
  } else {
    const d = document
    const i = function (): void {
      i.c(arguments)
    }
    i.q = []
    i.c = (args): void => {
      i.q.push(args)
    }
    w.Intercom = i
    const l = function (): void {
      const s = d.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.src = 'https://widget.intercom.io/widget/' + APP_ID
      const x = d.getElementsByTagName('script')[0]
      x.parentNode.insertBefore(s, x)
    }
    if (document.readyState === 'complete') {
      l()
    } else if (w.attachEvent) {
      w.attachEvent('onload', l)
    } else {
      w.addEventListener('load', l, false)
    }
  }
}

export const boot = (options = {}): void => {
  window && window.Intercom?.('boot', { app_id: APP_ID, ...options })
}

// Run whenever SPA route changes
export const update = (): void => {
  window && window.Intercom?.('update')
}
