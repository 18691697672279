import { TButton } from './buttons/button'
import { useMantineColorScheme, useComputedColorScheme } from '@mantine/core'
import { IconSun, IconMoon } from '@tabler/icons-react'
import { type ReactElement } from 'react'

export const ThemeButton = (): ReactElement => {
  const { setColorScheme, colorScheme } = useMantineColorScheme()
  const computedColorScheme = useComputedColorScheme('light', { getInitialValueInEffect: true })
  return (
    <TButton
      buttonType='icon-rounded'
      icon={colorScheme === 'dark' ? IconMoon : IconSun}
      onClick={() => setColorScheme(computedColorScheme === 'light' ? 'dark' : 'light')}
      variant='secondary'
    >
      Toggle color scheme
    </TButton>
  )
}
