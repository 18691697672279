import { TButton } from '../buttons'
import { useDebounce } from '../debounce'
import { useToastError } from '../useToastError'
import { SearchResultOption } from './SearchResultOption'
import { SearchScopeMenu } from './SearchScopeMenu'
import { SEARCHABLE_TARGETS, type SearchableTarget } from './types'
import { groupResults } from './util'
import { Size, useUniversalSearch } from '@front/model'
import { Box, Divider, Group, Loader, Modal, Stack, Text, TextInput, Title } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useNavigate, Link } from '@remix-run/react'
import { IconSearch, IconX } from '@tabler/icons-react'
import { isEmpty, isNotEmpty, type UniversalSearchScope, type UniversalSearchItem } from '@terros/common'
import { type ReactElement, useRef, useState } from 'react'

export const UniversalSearchModal = (): ReactElement => {
  const navigate = useNavigate()
  const [searchScopes, setSearchScopes] = useState<SearchableTarget[]>([...SEARCHABLE_TARGETS])
  const [searchValue, setSearchValue] = useState<string>('')
  const query = useDebounce(searchValue, 500)
  const {
    data: results = [],
    isLoading,
    error,
  } = useUniversalSearch({ query, scopes: searchScopes.map(toUniversalSearchScope) })
  useToastError(error)
  const [opened, { close, open }] = useDisclosure()

  const groupedResults = groupResults(results)

  const inputRef = useRef<HTMLInputElement>(null)
  return (
    <>
      <TButton
        icon={IconSearch}
        miw={{ base: undefined, sm: 300 }}
        onClick={() => {
          open()
        }}
        variant='secondary'
      >
        Search...
      </TButton>
      <Modal
        onClose={close}
        onFocus={() => {
          inputRef.current?.focus()
        }}
        opened={opened}
        size='lg'
        styles={{
          body: {
            padding: 0,
            minHeight: '50vh',
          },
          title: {
            width: '100%',
          },
          header: {
            gap: Size.space.xl,
          },
        }}
        title={
          <Stack>
            <Group justify='space-between'>
              <Title order={4}>Search</Title>
              <TButton color='neutral' onClick={close} variant='ghost'>
                Cancel
              </TButton>
            </Group>
            <Group gap={Size.space.md}>
              <TextInput
                className='flex-1 sm:min-w-80'
                leftSection={<IconSearch size={14} />}
                onChange={(e) => {
                  open()
                  setSearchValue(e.currentTarget.value)
                }}
                onFocus={(e) => e.target.select()}
                placeholder='Search...'
                ref={inputRef}
                rightSection={
                  <TButton buttonType='icon-rounded' icon={IconX} onClick={() => setSearchValue('')} variant='text' />
                }
                type='text'
                value={searchValue}
              />
              <SearchScopeMenu scopes={searchScopes} setScopes={setSearchScopes} />
            </Group>
          </Stack>
        }
        withCloseButton={false}
      >
        <Stack>
          <Box className='grid place-content-center'>
            {isLoading ? (
              <Loader size={16} />
            ) : (
              isEmpty(results) && <Text>{isEmpty(searchValue) ? 'Start typing to search' : 'No results found'}</Text>
            )}
          </Box>
          {Object.entries(groupedResults)
            .filter(([, items]) => isNotEmpty(items))
            .map(([group, items]) => (
              <Stack gap={Size.space.xl} key={group}>
                <Box px={Size.space['2xl']}>
                  <Text fw={700} size='lg'>
                    {group}
                  </Text>
                </Box>
                <Divider />
                <Stack p={Size.space.lg}>
                  {items.map((item, index) => (
                    <TButton
                      component={Link}
                      justify='start'
                      key={`${group}-${index}`}
                      onClick={() => {
                        close()
                      }}
                      size='md'
                      to={getNavigationUrl(group as SearchableTarget, item)}
                      variant='ghost'
                    >
                      <SearchResultOption result={item} />
                    </TButton>
                  ))}
                </Stack>
              </Stack>
            ))}
        </Stack>
      </Modal>
    </>
  )
}

const getNavigationUrl = (targetType: SearchableTarget, item: UniversalSearchItem): string => {
  switch (item.type) {
    case 'User':
      return `/user/${item.userId}`
    case 'Team':
      if (targetType === 'Role') return `/settings/role/${item.teamId}`
      return `/team/${item.teamId}`
    case 'Company':
      return `/companies/${item.companyId}`
    case 'Calendar':
      return `/calendar/${item.eventId}`
  }
}

const toUniversalSearchScope = (scope: SearchableTarget): UniversalSearchScope => {
  if (scope === 'Role') return 'Team'
  return scope
}
