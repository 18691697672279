import { GetHookResponse } from './hooks'
import { intercomKey } from './keys'
import { useTantalimQuery } from './query'
import { API } from '@terros/common'

export function useIntercomHash(platform: string): GetHookResponse<API.IntercomSuccess> {
  return useTantalimQuery<API.IntercomInput, API.IntercomSuccess, API.IntercomSuccess>({
    url: '/app/intercom',
    queryKey: intercomKey(platform),
    input: {
      platform,
    },
    convertResponse: (data) => data,
    options: {
      gcTime: Infinity,
      staleTime: Infinity,
    },
  })
}
