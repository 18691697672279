import { useAuth0 } from '@auth0/auth0-react'
import { BackendContext, type Stage } from '@front/model'
import { useQueryClient } from '@tanstack/react-query'
import { type UserId, isUserId } from '@terros/common'
import { type PropsWithChildren, type ReactElement, useRef } from 'react'

const IMPERSONATING_KEY = 'impersonating'

export const WebBackendProvider = ({ children }: PropsWithChildren): ReactElement => {
  const impersonating = useRef<UserId | undefined>(getImpersonatingSession())
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()
  const queryClient = useQueryClient()
  return (
    <BackendContext.Provider
      value={{
        getStage,
        setStage: () => {
          /*no-op on web, stage is set via url*/
        },
        getImpersonating: () => impersonating.current,
        setImpersonating: (userId?: UserId) => {
          if (userId) window.sessionStorage.setItem(IMPERSONATING_KEY, userId)
          else window.sessionStorage.removeItem(IMPERSONATING_KEY)
          impersonating.current = userId
          queryClient.resetQueries()
        },
        setAuth: () => {
          /*no-op on web, auth is set via Auth0*/
        },
        getToken: getAccessTokenSilently,
        isAuthenticated: () => isAuthenticated,
      }}
    >
      {children}
    </BackendContext.Provider>
  )
}

const getImpersonatingSession = (): UserId | undefined => {
  const stored = window.sessionStorage.getItem(IMPERSONATING_KEY)
  if (!stored) return
  if (isUserId(stored)) return stored
}

const getStage = (): Stage => {
  if (window.location.hostname.includes('dev')) return 'dev'
  return 'prod'
}
