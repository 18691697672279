import { ThemeButton } from './ThemeButton'
import { TButton } from './buttons'
import { PrefetchedUserInfo } from './entityInfo'
import { type PageRoute, Navigation } from './navigation'
import { NavigationCollapseButton } from './navigation/CollapseButton'
import { UniversalSearchModal } from './universalSearch'
import { useAuth0 } from '@auth0/auth0-react'
import { Size, useBackend, useProfile } from '@front/model'
import { AppShell, Box, Burger, Group, Stack, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import {
  IconHome,
  IconTableImport,
  IconUsersGroup,
  IconMapRoute,
  IconDoorExit,
  IconUsers,
  IconChartBar,
  IconCalendar,
  IconSettings,
  IconHandOff,
  IconBuildingSkyscraper,
  IconSparkles,
  IconClipboardCheck,
  IconVideo,
  IconTable,
  IconCode,
  IconSunWind,
  IconCalendarCheck,
  IconArrowsSplit,
} from '@tabler/icons-react'
import { useQueryClient } from '@tanstack/react-query'
import { getName, isDefined, isFiberCompany, isSunrun } from '@terros/common'
import type { ReactElement, ReactNode } from 'react'

export const LayoutWithoutAuth = ({
  children,
}: Readonly<{
  children: ReactNode
}>): ReactNode => {
  const { user, isAdmin } = useProfile()

  const [opened, { toggle }] = useDisclosure()
  const [navbarOpen, { toggle: toggleNavbar }] = useDisclosure(true)

  const pageLinks: PageRoute[] = [
    { path: '/', label: 'Home', matchFormat: 'exact', icon: IconHome },
    {
      path: '/calendar',
      label: 'Calendar',
      icon: IconCalendar,
    },
    {
      path: '/closers',
      label: 'Closer Availability',
      icon: IconCalendarCheck,
      scope: 'tantalim',
    },
    {
      path: '/account',
      label: 'Accounts',
      icon: IconTable,
    },
    {
      path: '/team',
      label: 'Teams',
      icon: IconUsers,
    },
    {
      path: '/user',
      label: 'Users',
      icon: IconUsersGroup,
    },
    {
      path: '/stats',
      label: 'Stats',
      icon: IconChartBar,
    },
    { path: '/area', label: 'Area Management', icon: IconMapRoute, scope: 'manager' },
    { path: '/dnk', label: 'Do Not Knock', icon: IconHandOff, scope: 'manager' },
    { path: '/import', label: 'Import', icon: IconTableImport, scope: 'admin' },
    {
      path: '/companies',
      label: 'Companies',
      icon: IconBuildingSkyscraper,
      scope: 'tantalim',
    },
    { path: '/feature', label: 'Features', icon: IconSparkles, scope: 'tantalim' },
    { path: '/task/managers', label: 'Tasks', icon: IconClipboardCheck, scope: 'tantalim' },
    { path: '/short/reports', label: 'Review Shorts', icon: IconVideo, scope: 'tantalim' },
    { path: '/short/upload', label: 'Upload Shorts', icon: IconVideo, scope: 'tantalim' },
    { path: '/sampleComponents', label: 'Components', icon: IconCode, scope: 'tantalim' },
    { path: '/settings', label: 'Settings', icon: IconSettings, scope: 'manager' },
  ]

  if (isSunrun(user?.companyId))
    pageLinks.splice(5, 0, {
      path: '/sunrun/oktaUsers',
      label: 'Sunrun Okta Users',
      icon: IconSunWind,
      scope: 'admin',
      matchFormat: 'exact',
    })

  if (isFiberCompany(user?.companyId))
    pageLinks.splice(4, 0, {
      path: '/assignAccounts',
      label: 'Account Assignment',
      icon: IconArrowsSplit,
      scope: 'admin',
      matchFormat: 'exact',
    })

  const logoHeight = 9

  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{
        width: navbarOpen ? { base: 200, md: 250 } : 70,
        breakpoint: 'sm',
        collapsed: { mobile: !opened },
      }}
      padding={0}
    >
      <AppShell.Header bg='blue'>
        <Group
          className='flex h-full items-center'
          gap={Size.space['3xl']}
          justify='space-between'
          px={Size.space['4xl']}
        >
          <img alt='Terros logo' height={logoHeight} src='/logo-light.png' width={logoHeight * 17.2} />
          <Group flex={1} justify='center' visibleFrom='sm'>
            <UniversalSearchModal />
          </Group>
          <Group hiddenFrom='sm'>
            <UniversalSearchModal />
            <Burger color='white' onClick={toggle} opened={opened} size='sm' />
          </Group>
          <Group flex='none' justify='flex-end' visibleFrom='sm'>
            <UserSection />
          </Group>
        </Group>
      </AppShell.Header>
      <AppShell.Navbar>
        <Box className='relative flex h-full flex-col justify-between gap-1' p={Size.space['3xl']} pos='relative'>
          <Navigation
            open={navbarOpen}
            routes={pageLinks.filter((link) => isDefined(link))}
            toggle={toggleNavbar}
            toggleBurger={toggle}
          />
          <Stack hiddenFrom='sm'>
            <UserSection />
          </Stack>
          <NavigationCollapseButton open={navbarOpen} toggle={toggleNavbar} visibleFrom='sm' />
          <Stack flex={1} justify='end'>
            <ThemeButton />
          </Stack>
        </Box>
      </AppShell.Navbar>
      <AppShell.Main>{children}</AppShell.Main>
    </AppShell>
  )
}

const UserSection = (): ReactElement => {
  const { loginWithRedirect, logout } = useAuth0()
  const { isAuthenticated } = useBackend()
  const { user, refetch } = useProfile()

  const backend = useBackend()
  const queryClient = useQueryClient()

  return isAuthenticated() ? (
    <>
      {backend.getImpersonating() && (
        <TButton
          onClick={() => {
            backend.setImpersonating()
            queryClient.resetQueries()
            setTimeout(refetch, 500)
          }}
          variant='secondary'
        >
          Stop Impersonating
        </TButton>
      )}
      {user && (
        <Group gap={Size.space['2xl']}>
          <PrefetchedUserInfo user={user} avatarOnly />
          <Text hiddenFrom='sm'>{getName(user)}</Text>
        </Group>
      )}
      <TButton
        onClick={() => logout({ logoutParams: { returnTo: `${window.location.origin}/` } })}
        rightSection={<IconDoorExit size={14} />}
        variant='secondary'
      >
        Sign Out
      </TButton>
    </>
  ) : (
    <TButton onClick={() => loginWithRedirect()}>Sign In</TButton>
  )
}
