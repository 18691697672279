import { TButton, type TButtonProps } from '../buttons'
import { type SearchableTarget } from './types'
import { useProfile } from '@front/model'
import { Menu, Switch } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import {
  IconSettings,
  type Icon,
  IconUser,
  IconUsersGroup,
  IconUserCog,
  IconBuildingSkyscraper,
  IconCalendarEvent,
} from '@tabler/icons-react'
import { forwardRef, type Dispatch, type ReactElement, type SetStateAction } from 'react'

type Props = {
  scopes: SearchableTarget[]
  setScopes: Dispatch<SetStateAction<SearchableTarget[]>>
}

type SearchScopeItem = {
  scope: SearchableTarget
  icon: Icon
  condition?: 'tantalim' | 'admin'
}

export const SearchScopeMenu = ({ scopes, setScopes }: Props): ReactElement => {
  const { isTantalim } = useProfile()
  const [opened, { open, close }] = useDisclosure()

  const toggleScope = (scope: SearchableTarget): void => {
    setScopes((prev) => (prev.includes(scope) ? prev.filter((s) => s !== scope) : [...prev, scope]))
  }

  const items = SEARCH_SCOPE_ITEMS.filter(({ condition }) => !condition || (condition === 'tantalim' && isTantalim))

  return (
    <Menu
      classNames={{
        label: 'w-full',
      }}
      closeOnClickOutside
      closeOnItemClick={false}
      onClose={close}
      onOpen={open}
      opened={opened}
      position='bottom-end'
      shadow='md'
      width={200}
    >
      <Menu.Target>
        <TButton buttonType='icon-rounded' icon={IconSettings} variant='ghost' />
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Searching for</Menu.Label>
        {items.map(({ scope, icon: Icon }) => (
          <Menu.Item
            key={scope}
            leftSection={<Icon size={16} />}
            onClick={() => toggleScope(scope)}
            rightSection={<Switch checked={scopes.includes(scope)} onChange={() => toggleScope(scope)} />}
          >
            {scope}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  )
}

const ButtonMenuItem = forwardRef<HTMLButtonElement, TButtonProps>((props, ref) => (
  <TButton color='neutral' variant='ghost' {...props} ref={ref} />
))

ButtonMenuItem.displayName = 'ButtonMenuItem'

const SEARCH_SCOPE_ITEMS: SearchScopeItem[] = [
  {
    scope: 'Company',
    icon: IconBuildingSkyscraper,
    condition: 'tantalim',
  },
  {
    scope: 'Role',
    icon: IconUserCog,
  },
  {
    scope: 'Team',
    icon: IconUsersGroup,
  },
  {
    scope: 'User',
    icon: IconUser,
  },
  {
    scope: 'Calendar',
    icon: IconCalendarEvent,
  },
]
