import { CompanyInfo } from '../CompanyInfo'
import { TeamInfo, UserInfo } from '../entityInfo'
import { getLevelName, Size, useCalendarEvent, useProfile, useTeam } from '@front/model'
import { Group, Skeleton, Stack, Text } from '@mantine/core'
import {
  type CompanySearchItem,
  type TeamSearchItem,
  type CalendarSearchItem,
  type UniversalSearchItem,
  type UserSearchItem,
  getName,
  type CompanyData,
  type TeamData,
} from '@terros/common'
import { DateTime } from 'luxon'
import { type ReactNode, type ReactElement } from 'react'

export const SearchResultOption = ({ result }: { result: UniversalSearchItem }): ReactElement => {
  switch (result.type) {
    case 'User':
      return <UserResultOption {...result} />
    case 'Calendar':
      return <CalendarResultOption {...result} />
    case 'Team':
      return <TeamResultOption {...result} />
    case 'Company':
      return <CompanyResultOption {...result} />
  }
}

const UserResultOption = ({ userId, description }: UserSearchItem): ReactElement => {
  return (
    <Group>
      <UserInfo avatarOnly linked={false} size='md' userId={userId} />
      <Text>{description}</Text>
    </Group>
  )
}

const TeamResultOption = ({ teamId, description }: TeamSearchItem): ReactNode => {
  const { data, isLoading } = useTeam(teamId)
  const { company } = useProfile()

  if (isLoading) return <Skeleton h={Size.space['5xl']} />

  const groupName = getGroupName(company, data?.team, description)

  return (
    <Group>
      <TeamInfo avatarOnly linked={false} size='md' teamId={teamId} />
      <Stack className='text-start' gap={0}>
        <Text>{groupName}</Text>
        {data && (
          <Text c='dimmed' size='sm'>
            <Text inherit>{data.members.length} members</Text>
          </Text>
        )}
      </Stack>
    </Group>
  )
}

const getGroupName = (company: CompanyData | undefined, team: TeamData | undefined, defaultName: string): string => {
  if (!team) return defaultName
  return `${getLevelName(company, team.level)}: ${team.name}`
}

const CompanyResultOption = ({ description, companyId }: CompanySearchItem): ReactElement => {
  return (
    <Stack className='text-start'>
      <Group>
        <CompanyInfo avatarOnly companyId={companyId} linked={false} size='md' />
        <Text>{description}</Text>
      </Group>
    </Stack>
  )
}

const CalendarResultOption = ({ description, eventId }: CalendarSearchItem): ReactElement => {
  const { data, isLoading } = useCalendarEvent(eventId)

  return (
    <Stack className='text-start' gap={0}>
      <Text>{description}</Text>
      {isLoading && <Skeleton h={Size.space['4xl']} />}
      {data && (
        <Text c='dimmed' size='sm'>
          <Text inherit>Appointment: {DateTime.fromMillis(data.eventDate).toLocaleString(DateTime.DATETIME_MED)}</Text>
          {data.owner && <Text inherit>Setter: {getName(data.owner)}</Text>}
          {data.attendee && <Text inherit>Closer: {getName(data.attendee)}</Text>}
        </Text>
      )}
    </Stack>
  )
}
