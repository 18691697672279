import switchClasses from './Switch.module.css'
import { themeRadius } from './util'
import { ColorPrimitives, Typography } from '@front/model'
import {
  createTheme,
  Select,
  Switch,
  TextInput,
  type MantineColorsTuple,
  InputLabel,
  Textarea,
  MultiSelect,
  InputBase,
  InputWrapper,
  NumberInput,
} from '@mantine/core'
import { DateInput, DateTimePicker } from '@mantine/dates'
import { isNotDefined } from '@terros/common'
import { getFontSizeCssProps, defaultFormFieldWrapper, defaultFormFieldLabel, defaultFormFieldInputStyles } from '@util'

const getMantineColorTupleFromPrimitive = (key: keyof typeof ColorPrimitives): MantineColorsTuple => {
  return Object.values(ColorPrimitives[key]) as unknown as MantineColorsTuple
}

export const theme = createTheme({
  colors: {
    blue: [
      '#a2acb4',
      '#8b97a2',
      '#74828f',
      '#5d6d7c',
      '#455969',
      '#2e4457',
      '#172F44',
      '#152a3d',
      '#122636',
      '#102130',
    ],
    brand: getMantineColorTupleFromPrimitive('blue'),
    yellow: getMantineColorTupleFromPrimitive('yellow'),
    tan: getMantineColorTupleFromPrimitive('tan'),
    neutral: getMantineColorTupleFromPrimitive('neutral'),
    rose: getMantineColorTupleFromPrimitive('rose'),
    orange: getMantineColorTupleFromPrimitive('orange'),
    emerald: getMantineColorTupleFromPrimitive('emerald'),
    indigo: getMantineColorTupleFromPrimitive('indigo'),
    black: getMantineColorTupleFromPrimitive('black'),
    white: getMantineColorTupleFromPrimitive('white'),
  },
  fontFamily: 'Sora, sans-serif',
  fontSizes: Object.entries(Typography.size).reduce<Record<string, string>>((acc, [key, value]) => {
    acc[key] = `${value}px`
    return acc
  }, {}),
  lineHeights: Object.entries(Typography.line).reduce<Record<string, string>>((acc, [key, value]) => {
    acc[key] = `${value}px`
    return acc
  }, {}),
  primaryColor: 'brand',
  primaryShade: { light: 6, dark: 7 },
  defaultRadius: 'md',
  radius: themeRadius,
  components: {
    Switch: Switch.extend({
      defaultProps: {
        classNames: switchClasses,
        styles: {
          label: getFontSizeCssProps('sm'),
        },
      },
    }),
    TextInput: TextInput.extend({
      vars: (_, props) => {
        const defaultStyle = {
          root: defaultFormFieldWrapper,
          label: defaultFormFieldLabel,
        }
        if (isNotDefined(props.size) || props.size === 'sm') {
          return {
            ...defaultStyle,
            input: {
              height: 34,
              minHeight: 34,
              borderWidth: 1,
            },
          }
        }
        return defaultStyle
      },
    }),
    Select: Select.extend({
      defaultProps: {
        styles: defaultFormFieldInputStyles,
      },
    }),
    DateInput: DateInput.extend({
      defaultProps: {
        styles: defaultFormFieldInputStyles,
      },
    }),
    DateTimePicker: DateTimePicker.extend({
      defaultProps: {
        styles: defaultFormFieldInputStyles,
      },
    }),
    Textarea: Textarea.extend({
      defaultProps: {
        styles: defaultFormFieldInputStyles,
      },
    }),
    MultiSelect: MultiSelect.extend({
      defaultProps: {
        styles: defaultFormFieldInputStyles,
      },
    }),
    InputBase: InputBase.extend({
      defaultProps: {
        styles: defaultFormFieldInputStyles,
      },
    }),
    InputLabel: InputLabel.extend({
      defaultProps: {
        styles: {
          label: defaultFormFieldLabel,
        },
      },
    }),
    InputWrapper: InputWrapper.extend({
      defaultProps: {
        styles: defaultFormFieldInputStyles,
      },
    }),
    NumberInput: NumberInput.extend({
      defaultProps: {
        styles: defaultFormFieldInputStyles,
      },
    }),
  },
})
