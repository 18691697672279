import {
  type CalendarSearchItem,
  type CompanySearchItem,
  type TeamSearchItem,
  type UserSearchItem,
} from '@terros/common'

export type GroupedSearchResults = {
  calendar: CalendarSearchItem[]
  company: CompanySearchItem[]
  team: TeamSearchItem[]
  user: UserSearchItem[]
}

export const SEARCHABLE_TARGETS = ['Calendar', 'Company', 'Team', 'Role', 'User'] as const
export type SearchableTarget = (typeof SEARCHABLE_TARGETS)[number]
