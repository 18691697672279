import { type SearchableTarget } from './types'
import { type UniversalSearchItem } from '@terros/common'

export const groupResults = (results: UniversalSearchItem[]): Record<SearchableTarget, UniversalSearchItem[]> => {
  const grouped: Record<SearchableTarget, UniversalSearchItem[]> = {
    Company: [],
    Role: [],
    Team: [],
    User: [],
    Calendar: [],
  }

  results.forEach((result) => {
    // waiting for UniversalSearchItem to be updated with teamType property
    grouped[result.type].push(result)
  })

  return grouped
}
