import { Size } from '@front/model'

export const themeRadius = Object.entries(Size.radius).reduce<Record<string, `${string}px`>>((acc, [key, value]) => {
  acc[key] = `${value}px`
  return acc
}, {})

export const themeSpacing = Object.entries(Size.space).reduce<Record<string, `${string}px`>>((acc, [key, value]) => {
  acc[key] = `${value}px`
  return acc
}, {})
