import { GetHookResponse } from '../hooks'
import { useTantalimQuery } from '../query'
import { type API, HOUR, isEmpty, isNotEmpty, type UniversalSearchItem } from '@terros/common'

export function useUniversalSearch(
  input: API.UniversalSearchInput,
  enabled = true
): GetHookResponse<UniversalSearchItem[]> {
  return useTantalimQuery<API.UniversalSearchInput, API.UniversalSearchSuccess, UniversalSearchItem[]>({
    url: '/search',
    queryKey: ['search', input],
    input: {
      ...input,
      scopes: isEmpty(input.scopes) ? undefined : input.scopes,
    },
    convertResponse: (data) => data.results,
    options: {
      gcTime: HOUR,
      staleTime: HOUR,
      enabled: isNotEmpty(input.query) && enabled,
    },
  })
}
